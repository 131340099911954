/* Footer */
#footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $color2;
    .container-footer {
        @include flex_between;

        @media (max-width: $breakpoint-md) {
            flex-direction: column;
        }
        .footer_logo{
            max-width: 30%;

            img{
                max-width: 15rem;
                margin-bottom: 1rem;
                @media (max-width: $breakpoint-md) {
                    max-width: 100%;
                }
            }
        }
        .reseaux_footer {
            .sociaux_footer{
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                    color: $color1;
                    margin: 0 1rem;
                    &.icon {
                        font-size: 5.5rem;
                    }
                    &:hover,
                    &:focus {
                        color: $color11;
                    }
                }
        }
        }

    }

    .menu_footer{
        ul{
            list-style-type: none;
            padding-left: 0;
        }
        .level1{
            display: flex;
            justify-content: center;
            align-items: center;
            > li {
                position: relative;
                padding-left: 2rem;
                padding-right: 2rem;
                @media (max-width: $breakpoint-md) {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    font-size: 1.1rem;
                }
                a {
                    color: $color1;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                &:after {
                    content: '';
                    @include absolute_container;
                    left: auto;
                    border-right: 1px solid $color3;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .container-footer {
        .footer_logo {

        }
        .adresse_content {
            .item1 {
                img {
                    @media (max-width: $breakpoint-md) {
                        width: 6rem;
                    }
                }
            }
            .item2 {
                font-size: 2.6rem;

                @media (max-width: $breakpoint-md) {
                    font-size: 1.6rem;
                }
            }
        }
    }

}

/* Signature Kyxar */
.kyxar {
    position: absolute;
    z-index: 9;
    white-space: nowrap;
    right: 1rem;
    margin-top: -3rem;
    padding: 0;
    line-height: 2.5rem;
    a{
        color: $color1;
        font-size: 1rem;
        text-decoration: none;
        letter-spacing: .1rem;
        position: relative;
        .none {
            position: absolute;
            bottom: 3.8rem;
            right: 2.8rem;
            height: 0;
            overflow: hidden;
            color: $color2;
            span {
                display: inline-block;
                background-color: #fff;
                border-radius: .3rem;
                padding: .5rem 1rem;
                line-height: 1;
                font-weight: 300;
                font-size: 1rem;
            }
        }
        &:hover{
            color: $color1;
            .none {
                overflow: visible;
            }
        }
    }
}
