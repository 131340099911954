.presentation-home {
  margin: 5rem 0;
  color: $color1;
  .title {
    color:$color1;
    text-align: center;
    font-size: 4.4rem;
    line-height: 1;
    margin-bottom: 2rem;
  }
  .presentation-1 {
    .h2 {
      color: $color3;
      font-size: 2.6rem;
      font-weight: normal;
      text-transform: none;
    }
    ul {
      list-style-position: inside;
      margin: 2rem 0;
      padding: 0;
    }
  }
  .presentation-2 {
    font-size: 21px;
  }
}


/* rassurance-home */
.rassurance-home {
  .rassurance_slider,
  .noslider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .slide {
      margin-bottom: 2rem;
      min-width: 10rem;
      .s_link,
      .no_link {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        figure {
          height: 9.6rem;
          @media (max-width: $breakpoint-md) {
            height: 6.6rem;
          }

          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
      .islide_content {
        text-align: center;
        margin-top: 1rem;
        .h3 {
          color: $color3;
          font-size: 1.6rem;
          font-weight: normal;
          text-transform: uppercase;
          line-height: 1.3;
        }
      }
    }

  }
}
