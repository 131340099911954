.page_contact #contenu {
  margin-top: 3rem;
  margin-bottom: 0;
  .h3 {
    margin-bottom: 3.5rem;
  }
  .required-label {
    font-size: 1.2rem;
    margin-top: 1rem;
    .icon {
      font-size: 3rem;
      line-height: 1;
    }
  }
  button[type="submit"] {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  .bloc_contact {
    margin-bottom: 2rem;
    display: none;
  }
}

.head-contact{
  p,h2{
    text-align: center;
  }
  .radio-button{
    justify-content: center;
  }
}