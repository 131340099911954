/* Slider */
.slick-slider{
  padding-left: 0;
}
.slick-loading .slick-list
{
  background: none;
}
.slick-track{
  display: flex;
}
.slick-slide{
  height: inherit;
}

/* Slick slider arrows + dots */
.slick-prev,
.slick-next{
  width: 3rem;
  height: 3rem;
  z-index: 1;
}

.slick-next::before,
.slick-prev::before {
  font-family: kfont!important;
  color: $color3;
  transition: color .3s;
  font-size: 3rem;
}

.slick-dotted.slick-slider {
  margin-bottom: 4rem;
}

.slick-dotted .slick-dots{
  line-height: 0;
  li{
    width: 1.3rem;
    height: 1.3rem;
    button{
      width: 1.3rem;
      height: 1.3rem;
      &::before{
        content: "";
        font-family: Sans-serif!important;
        background-color: $color3;
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .slick-prev{
    left: -4rem;
  }
  .slick-next{
    right: -4rem;
  }
}

@media (max-width: $breakpoint-md) {
  .slick-dots{
    right: 2rem;
    bottom: 1.5rem;
  }
  .slider_competences{
    margin-top: 3rem;
    .slick-dots{
      position: static;
    }
  }
}