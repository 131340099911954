
/* news-home */
.news-home {
  .h2 {
    text-align: center;
    font-size: 4.4rem;
    margin-bottom: 5rem;
  }
  .news_slider,
  .noslider {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    * div:not(.date_news):not(.tag_list) {
      height: 100%;
    }

    .slide {
      display: flex;
      justify-content: center;
      align-items: stretch;

      flex: 1;
      margin-bottom: 0;
      @media (max-width: $breakpoint-sm) {
        margin-bottom: 4rem;
      }

      .slide-content {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: stretch;
        flex-direction: column;
        margin: 0 4rem;
        min-width: 280px;
        flex: 1;
        @media (max-width: $breakpoint-md) {
          margin: 0 1rem;
        }
        @media (max-width: $breakpoint-sm) {
          margin: 0 0;
        }

        figure {

          img {
            /*object-fit: contain;
            width: 100%;
            height: 100%;*/
          }
        }

        .islide_content {

          margin-top: 1rem;
          flex: 1;
          display: flex;

          .islide {
            flex: 1;
            display: flex;
            flex-direction: column;

            .h3 {
              color: $color2;
              font-size: 2.1rem;
              font-weight: bold;
              text-transform: uppercase;
              line-height: 1.3;
            }
            .desc {
              font-size: 1.8rem;
              margin: 1rem 0;
            }
            .button {
                padding: 0.6rem 3.5rem;
            }
            .button-content {
              margin-top: auto;
              margin-bottom: 0;
            }
          }

        }
        .img_content {
          position: relative;
          display: block;

          .tag_list {
            position: absolute;
            bottom: 0.5rem;
            right: 0.5rem;
          }
        }
      }
    }

  }
}

/* Affichage date et tags */
.tag_label,
.tag_actu {
  display: inline-block;
  padding: .2rem 0.5rem;
  color: $color1;
  background-color: $color2;
  font-size: 1rem;
  + .tag_label{
    margin-left: .2rem;
  }
}

.date_news{
  position: absolute;
  top: 0;
  right: 0;
  color: $color1;
  background-color: $color2;
  padding: .2rem .6rem;
  z-index: 1;
  font-size: 1rem;
}

/*** Liste des news ***/

.liste_articles article{
  margin-bottom: 2rem;
  padding: 0px 5%;
  .news_card{
    height: 100%;
    background-color: #eee;
    .link-news-img{
      display: block;
      position: relative;
      .list_tags{
        position: absolute;
        left: .4rem;
        bottom: .4rem;
        .tag_label{
          font-size: 1.2rem;
          padding: .2rem .6rem;
          + .tag_label{
            margin-left: 0;
          }
        }
      }
    }
    .news_card_content{
      height: 15rem;
      padding: .5rem 1rem 1rem;
      margin-top: 1rem;
      .desc{
        /*color: $color1;*/
      }
    }
    .button{
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

/*** News active ***/
.news_content{
  margin-top: 3rem;

  .title {
    text-align: left;
  }
  .img_news_c{
    position: relative;
    .date_news{
      top: 1rem;
      right: 1rem;
    }
  }
  .news_txt{
    margin-top: 2rem;
  }
}
