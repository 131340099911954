/* Access topbar */
.access{
  .container {
    min-height: 3.5rem;
    @include flex_between;
    color: $color1;
    font-size: 2.6rem;
    font-weight: normal;
    @media (max-width: $breakpoint-md) {
      min-height: 1.5rem;
      font-size: 1.4rem;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 0.9rem;
    }
    .quotes{
      position:relative;
      width:auto;
      display:inherit;
      span{
        position:absolute;
        color:$color3;
        font-size:1.5em;
        &:first-of-type{
          left:-17%;
          bottom:3%;
          transform: rotate(-15deg);
        }
        &:last-of-type{
          right:-18%;
          top:4%;
          transform: rotate(195deg);
        }
      }
    }
  }
}

/* HEADER */
.header_content .container {
  height: 22rem;
  @media (max-width: $breakpoint-md) {
    height: auto;
  }
}
#header {
  background-color: $color2;
  position: relative;
  z-index: 1002;
}
.header_content {
  /*@include box-shadow;*/
  @media (max-width: $breakpoint-md) {
    padding-bottom: 1rem;
  }
  .container{
    @include flex_between;
    align-items: end;
  }
  .left_col {
    margin-top: auto;
    margin-bottom: 5rem;
    @media (max-width: $breakpoint-md) {
      flex: 1;
      margin-bottom: auto;
    }
    .header_logo img{
      width: 12.6rem;
      @media (max-width: $breakpoint-md) {
        height: 7rem;
        width: 10rem;
      }
      @media (max-width: $breakpoint-sm) {
        height: 4rem;
        width: calc( 100% );
      }
    }
  }
  .right_col{
    @include flex_between;
    flex-direction: column;
    height: 80%;
    margin-bottom: 0;
    margin-top: auto;

    .sociaux_header{
      @include flex_around;
    }
  }
}

/* Colone droite : Menu principal + réseaux sociaux */
.right_col{
  width: calc(100% - 30rem);
  #primary_nav_wrap {
    /*width: calc(100% - 22rem);
    margin-right: 5rem;
    margin-bottom: 0.3rem;*/

    ul.first_level {
      display: flex;
      justify-content: space-around;
      align-items: center;
      > li{
        /*height: 5.2rem;*/
        text-align: center;
        &.dropdown{
          position: static;
          .second_level{
            position: absolute;
            top: calc( 100% + 2rem);
            left: 0;
            right: 0;
            min-width: 24rem;
            /*transform: translateX(-50%);*/
            background-color: $color3;
            text-align: left;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            box-shadow: 0 2rem 2rem 0 rgba(0, 0, 0, .3);
            opacity: 0;
            visibility: hidden;
            filter: blur(0.5rem);
            transition: opacity .2s, visibility .2s, top .2s, filter .6s;

            .title_smenu{
              font-size: 4.5rem;
              font-weight: 400;
              color: $color1;
              height: 16.4rem;
              text-align: center;
              padding-top: 4rem;
              &::after{
                content:'';
                position: absolute;
                top: 2.6rem;
                right: 0;
                display: inline-block;
                width: .1rem;
                height: 11.2rem;
                background-color: #fff;
              }
            }
            ul {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: row;
              justify-content: start;
              height: 100%;
              max-height: 140px;
              margin-top: auto;
              margin-bottom: auto;
              padding-left: 5rem;

              li {
                margin: 0.5rem 0;

                a {
                  color: $color1;
                  font-size:3rem;
                  padding-right: 5rem;
                  &:hover {
                    color: $color2;
                  }
                }
                &.current {
                  a {
                    color: $color2;
                  }
                }
              }
            }

          }
          &:hover {
            .second_level {
              opacity: 1;
              visibility: visible;
              top: calc( 100% - 0.2rem);
              filter: blur(0);
            }
          }
        }
      }
    }
  }

  .sociaux_header a{
    font-size: 2rem;
    margin-left: 1.5rem;
    line-height: 0;
  }
}

.menu {
  width: calc(100%);
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .level1 {
    display: flex;
    justify-content: space-around;
  }

  &-item {
    @include flex_center;
    transition: background 0.3s ease-in-out;
    position: relative;
    width: 100%;

    .level2 {
      /*width: 100%;
      transform-origin: top;
      transform: rotateX(-90deg);
      background-color: #fff;
      z-index: 1;
      transition: transform 0.3s linear;*/
    }

    &:hover {
      span{
        /*cursor: default;*/
        /*color: $color1;*/
      }
      .level2 {
        transform: rotateX(0deg);
      }
      > a,
      > a:hover,
      > a:focus {
        background-color: $color2;
      }
    }

    > a {
      @include flex_center;
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      min-height: 4rem;
      background-color: $color3;
      color: $color1;
      line-height: 1;
    }
  }
}

/* menu pro */
.menu-pro {
  width: calc(100%);
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: $breakpoint-sm) {
    /*display: none;*/
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    justify-content: space-around;
    li {
      a {
        font-size: 2.4rem;
        color: $color1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @media (max-width: $breakpoint-md) {
          font-size: 1.5rem;
        }
        .icon {
          font-size: 4.6rem;
          margin-left: 1rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          @media (max-width: $breakpoint-md) {
            font-size: 3.5rem;
          }
        }
        &:hover,
        &:focus {
          color: $color11;
        }
        .text {
          @media (max-width: $breakpoint-sm) {
            display: none;
          }
        }
      }
    }
  }
}



/* overlay */
.overlay  {
  &::before{
    content:'';
    position: fixed;
    z-index: 3;
    top: 0;
    bottom:0;
    left:0;
    right: 0;
    background-color: rgba(0,0,0,0.3);
    transition: all .3s;
  }
  #cd-main {
    #contenu {
      filter: blur(0.6rem);
      transition: all .6s;
    }
  }
}


/* Sticky */
#header.sticky + #contenu{ //Compensation de la position fixed pour éviter le saut de page
  margin-top: 22.4rem; //height #header - height #header.sticky .container

  @media (max-width: $breakpoint-md) {
    margin-top: 5rem;
  }
}

#header.sticky .menu-item a,
#header.sticky .header_content .container{
  height: 5rem;
}

#header.sticky .header_content{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color2;
  z-index: 1001;
  border-bottom: none;
  box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .3);
  .left_col {
    margin-bottom: auto;
    text-align: center;
    .logo {
      width: 4.1rem;
      margin-top: 0;
      margin-left: 1rem;
      margin-right: 4rem;
      height: auto;
      @media (max-width: $breakpoint-md) {
        margin-top: 0.4rem;
      }
    }

  }
  .right_col {
    height: auto;
    flex-direction: row;
    width: calc(100% - 10rem);

    .menu-pro {
      width: auto;
      margin-right: 1rem;
      display: inline-flex;
      li {
        margin-left: 1rem;
        margin-right: 1rem;
        a {
          font-size: 1.5rem;
          .icon {
            font-size: 3.5rem;
          }
          .text {
            display: none;
          }
        }
      }

    }
  }
  #primary_nav_wrap ul.first_level{
    .second_level {
      top: 5rem;
    }
  }
}

@media (max-width: $breakpoint-lg) {
}

@media (max-width: $breakpoint-md) {
  .menu{
    display: none;
  }
  .right_col{
   /* width: auto;*/
  }
}

@media (max-width: $breakpoint-sm) {
  .right_col{
    width: auto;
  }
}

@media (max-width: $breakpoint-xs) {
}

