/* Slider avec slide affichée 1 par 1 */
.singleslide,
.singleslide .slide img {
  /*height: 50rem;*/
  @media (max-width: $breakpoint-md) {
    min-height: 25rem;
  }
}

.singleslide{
  padding-left: 0;
  margin-bottom: 0;
  .slide{
    position: relative;
    display: block !important;
    img{
      width: 100%;
      object-fit: cover;
    }
    .islide_content{
      @include absolute_container;
      @include flex_center;
      .islide{
        width: auto;
        padding: 4rem;
        text-align: center;
        background-color: rgba(255, 255, 255, .5);
        margin-left: 5rem;
        margin-right: auto;
        @media (max-width: $breakpoint-md) {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          padding: 1rem;
          background-color: rgba(255, 255, 255, .5);
        }
        .h1 {
          justify-content: center;
          margin-bottom: 1.5rem;
          margin-left: auto;
          margin-right: auto;
          font-size: 2.9rem;
          color: $color2;
          @media (max-width: $breakpoint-md) {
            font-size: 2.2rem;
          }
        }
        .h3 {
          margin-bottom: 3rem;
          font-size: 2.9rem;
          color: $color2;
          font-weight: 300;
          @media (max-width: $breakpoint-md) {
            margin-bottom: 2rem;
            font-size: 2.2rem;
          }
        }
        .button{
          cursor: pointer;
          &:hover{
            /*color: $color1;
            background-color: #fff;*/
          }
        }
      }
    }
  }
  .slick-prev {
    left: 3.5rem;
  }
  .slick-next {
    right: 3.5rem;
  }
  .slick-next::before,
  .slick-prev::before {
    color: $color2;
  }
  .slick-dots{
    bottom: 1.5rem;
    li button::before{
      background-color: $color2;
    }
  }
}

/* Slider avec plusieurs slides affichées */
.multi_slider {
  margin-left: -1rem;
  margin-right: -1rem;
  .slick-slide{
    position: relative;
    background-color: #eee;
    margin-left: 1rem;
    margin-right: 1rem;
    .islide_content{
      padding: .5rem 1rem 1rem;
      .desc{
        height: 7rem;
        overflow: hidden;
      }
    }
  }
  .slick-prev {
    left: -4rem;
  }
  .slick-next {
    right: -4rem;
  }
}


/* Slider avec des vignettes (page news) */

.slider-single{
  .image_produit_container{
    position: relative;
    padding-bottom: 75.6%; // A ajuster en fonction du ratio de l'image
    .image_produit_content{
      @include absolute_container;
      @include box_shadow;
      @include flex_center;
      margin: 1rem;
      cursor: pointer;
    }
  }
}

.slider-nav{
  .slick-track{
    margin: 0 1rem 1rem;
    .slick-slide{
      margin-right: 1.5rem;
      img{
        object-fit: cover;
        @include box_shadow_light;
        cursor: pointer;
        height: 7.5rem;
        border: 2px solid transparent;
      }
    }
  }
  .slick-slide.is-active img{
    border-color: $color1;
  }
}

.fancybox-thumbs__list a::before {
  border: 4px solid $color1;
}

.tag_actu{
  display: inline-block;
  color: #fff;
  background-color: $color1;
  padding: .1rem .3rem;
  margin-bottom: .4rem;
}



/* listeslider */
.listeslider {
  .item_link {
    color: $color1;
    .item {
      position:relative;
      overflow: hidden;
      figure {
        transition: all .2s ease-in;

      }
      .desc {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        padding: 1px;
        transition: all .2s ease-in;

        &.couleur1 {
          background-color: rgba(0,159,227, 0.6);
        }
        &.couleur2 {
          background-color: rgba(58,170,53, 0.6);
        }

        p {
          margin: auto;
        }
        .t1 {
          font-size: 2.3rem;
        }
      }
    }
  }
  a.item_link:hover {
    /*opacity: 0.8;*/
    figure {
      transform: scale(1.1);
    }
    .desc {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

/* partenaire slider */
.partenaire-home {
  .h2 {
    margin-bottom: 6rem;
    color: $color3;
    font-size: 4.4rem;
  }

  .gallery.partenaires-home {
    .h2 {
      margin-bottom: 4rem;
    }

    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;

      .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto !important;

        .slide {
          .s_link,
          .no_link {
            height: 8rem;
            margin: 0 2rem;

            img {
              object-fit: contain;
              height: 100%;
              width: 100%;

            }
          }
        }
      }
    }

    .slick-dots {
      right: auto;
      bottom: -2.5rem;
    }
  }
  .partenaire-desc {
    font-size: 2rem;
    max-width: $breakpoint-sm;
    margin: 4rem auto;
  }
}