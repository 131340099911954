.textcenter{
  text-align: center;
}

// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
}

.ratio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (var(--ratio, calc(1))));
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
}

/* chemin img */
/* # {$base-img-url} */
$base-img-url: "../img/theme";

@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: $breakpoint-xl;
  }
}

@media (max-width: $breakpoint-sm) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .desktop {
    display: none;
  }
}
