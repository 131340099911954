/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 */

@import 'bootstrap/bootstrap-reboot.scss';
@import 'bootstrap/bootstrap-grid.scss';

@import "bootstrap/utilities/align";
@import "bootstrap/utilities/clearfix";
@import "bootstrap/utilities/sizing";
@import "bootstrap/utilities/float";
@import "bootstrap/utilities/display";

////@import "bootstrap/root";
////@import "bootstrap/type";
////@import "bootstrap/images";
////@import "bootstrap/code";
//@import "bootstrap/tables";
//@import "bootstrap/forms";
////@import "bootstrap/buttons";
////@import "bootstrap/transitions";
////@import "bootstrap/dropdown";
////@import "bootstrap/button-group";
////@import "bootstrap/input-group";
////@import "bootstrap/custom-forms";
////@import "bootstrap/nav";
////@import "bootstrap/navbar";
////@import "bootstrap/card";
////@import "bootstrap/breadcrumb";
//@import "bootstrap/pagination";
////@import "bootstrap/badge";
////@import "bootstrap/jumbotron";
////@import "bootstrap/alert";
////@import "bootstrap/progress";
////@import "bootstrap/media";
////@import "bootstrap/list-group";
////@import "bootstrap/close";
////@import "bootstrap/toasts";
//@import "bootstrap/modal";
////@import "bootstrap/tooltip";
////@import "bootstrap/popover";
////@import "bootstrap/carousel";
//@import "bootstrap/spinners";
//@import "bootstrap/utilities";
@import "bootstrap/print";

