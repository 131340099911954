//
//  CONTAINER ESPACE PRO
//

.container_espace_pro{
  /*width: 70%;
  margin: auto auto auto 300px;*/

  @media (max-width: $breakpoint-lg) {
    width: 100%;
    margin: auto auto auto 0;
  }
}
.container_espace_pro .cms {
  min-height: 1000px;
}

//
//  PAGE ACCUEIL ESPACE PRO
//

#espace_client_menu_home{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  list-style: none;
  margin: 0;

  width: 100%;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-md) {
    padding: 0;
  }
}
#espace_client_menu_home li{
  //background: gray;
  width: calc(50% - 20px);
  margin: 10px;
  display: flex;
  align-items: start;
  justify-content: center;
}
#espace_client_menu_home a{
  width: 150px;
  display: block;
}
#espace_client_menu_home .bloc_item{

  @media (max-width: $breakpoint-md) {
    text-align: center;
  }
}
#espace_client_menu_home .titre_menu{
  margin-top: 16px;
  color: #00aeef;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 34px;
}
.titre_espace_pro{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 10px;
}
.titre_espace_pro h1 {
  font-size: 2.5rem;
  width: 300px;
  line-height: 35px;
}
#espaceClientMenu .question_bloc{
  margin-top: 80px;
  margin-bottom: 20px;
}
#espaceClientMenu #deconnexion{
  margin-top: 80px;
  margin-bottom: 20px;
}

//
//  PAGE PROFILE
//

.page_profile #contenu {
  margin-bottom: 0;
}
#espace_client .bloc_stitle{
  margin-bottom: 20px;
}
#espace_client .formline {
  margin-bottom: 8px;
}


//
//  MENU ESPACE PRO
//

.espace-client-container-menu {
  /*position: fixed;
  left: 0;
  z-index: 1001;*/
  border-bottom: none;
  box-shadow: 0 0.2rem 2rem 0 rgb(0,0,0);
  margin-top: 16px;
  width: 28rem;
  background: #581d53;

  @media (max-width: $breakpoint-md) {
    width: auto;
  }


  @media (max-width: $breakpoint-lg) {
    /*width: 100%;
    margin-top: 2px;
    display: block;
    position: relative;
    margin-bottom: 30px;*/
  }
}
.espace-client-container-menu.sticky{
  /*top: 54px;
  @media (max-width: $breakpoint-lg) {
    top: 0;
  }*/
}
.espace-client-container-content {
  flex-grow: 1;
  min-height: 80vh;
  width: 65%;
  @media (max-width: $breakpoint-lg) {
    width: 100%;
  }
  h1 {
    //font: normal normal 800 30px/29px Nunito Sans !important;
    //letter-spacing: 0 !important;
    color: #fff !important;
  }
}
.espace-client-container-content > * {
  padding: 30px 0 30px 60px;
  width: 70%;
  @media (max-width: $breakpoint-xl) {
    padding: 30px;
    width: 100%;
  }
  @media (max-width: $breakpoint-lg) {
    padding: 15px;
  }
}
#espace-client-menu {
  padding: 24px;

  @media (max-width: $breakpoint-lg) {
    width: 100%;
  }
  .espace-client-welcome {
    color: #ea5b0c;
    //font: normal normal 600 11px/20px Khand;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    padding: 8px 0 8px 0;
    min-width: 180px;
  }
  h2 {
    margin: 0;
    //font: normal normal 800 35px/34px Nunito Sans;
    letter-spacing: 0px;
    color: #fff;
  }
  .espace-pro-menu {
    display: flex;
    margin-top: 30px;
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }
  .espace-pro-menu {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 15px 10px;
      color: #fff;
      //font: normal normal 600 16px/16px Nunito Sans;
      //letter-spacing: 1.8px;
      border-radius: 6px;

      &.level2{
        font-weight: 700;
      }

      &.active {
        color: #ea5b0c;
        font-weight: 600;
        //background: #6b2465;
      }
      &:not(.active):hover {
        background: rgba(255,255,255,.1);
      }
      .icon {
        width: 35px;
        @media (max-width: $breakpoint-lg) {
          width: 25px;
        }
      }
    }

    ul {
      li {
        ul {
          li {
            a {
              padding: 5px 10px;
            }
          }
        }
      }
    }

  }
}

#espace-client-menu ul{
  list-style: none;
  padding: 0 0 0 5px;
}
#espace-client-menu li{
  width: 220px;
}
#espace-client-menu .level1+ul li{
  width: 215px; // on compense les 5px padding left du sous niveau
}

@media (max-width: $breakpoint-lg) {
  #espace-client-menu li {
    width: 100%;
  }
  #espace-client-menu .level1 + ul li {
    width: 100%;
  }
}

// par défaut on cache les sous menu du menu espace pro
.level1+ul{
  display: none;
}
.level2+ul{
  display: none;
  margin-left: 20px;
  margin-bottom: 10px;
}
.level3+ul{
  display: none;
  margin-left: 40px;
  margin-bottom: 10px;
}

.icon-bottom{
  color:#ea5b0c;
  display: none;
  @media (max-width: $breakpoint-lg) {
    display: inline;
  }
}
.icon-top{
  color:#ea5b0c;
  display: none;
  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}
#zone_titre_menu_espace_pro{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.see_more{
  font-size: 3rem;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
}
.see_more_item{
  display: block;
}
.see_less_item{
  display: none;
}
#espace-client-menu .level.open{
  .see_more_item{
    display: none;
  }
  .see_less_item{
    display: block;
  }
}