@mixin title_global{
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
}

@mixin h1 {
  position: relative;
  color: $color2;
  font-size: 3.7rem;
  font-weight: 700;
  margin: 1rem 0 .5rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: $breakpoint-md) {
    font-size: 3rem;
    letter-spacing: normal;
  }
  .icon {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@mixin h2{
  font-size: 3.5rem;
  color: $color3;
  margin: .6rem 0;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  @media (max-width: $breakpoint-md) {
    font-size: 2.2rem
  }
}

@mixin h3{
  font-size: 2.5rem;
  color: $color2;
  margin: .6rem 0;
  font-weight: 700;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h4 {
  font-size: 2rem;
  color: $color2;
  margin: .6rem 0;
  font-weight : 400;
  line-height: 1;
  text-transform: uppercase;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}