#body_cms .content .button,
.button {
  display : inline-block;
  color: $color1;
  background-color: $color3;
  border: .1rem solid $color3;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.2rem 3.5rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease-in;
  &:hover,
  &:focus {
    color: $color3;
    background-color: $color1;
  }
  &.button-small {
    font-size: 1rem;
    padding: .6rem 1.2rem;
  }
  &.button-big,
  &.button-xl {
    font-size: 1.8rem;
    padding: 2.4rem 5rem;
  }
  &.button-outline {
    color: $color3;
    background-color: $color1;
    &:hover,
    &:focus {
      color: $color1;
      background-color: $color3;
    }
  }
  &:not([href]){
    cursor: default;
  }
}
