/*! Popin SEO */
#desc_f,
#desc_h {
  position: relative;
}

#desc_h {
  text-align: justify;
  margin: 0 auto 2rem auto;
  padding: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fff;
}

#colorbox #desc_h {
  padding: 4rem 4rem .5rem;
  font-size: 110%;
  text-align: justify;
}

#desc_f {
  text-align: center;
  margin-top: 1.5rem;
  overflow: hidden;
  font-size: 1.2rem;
  font-style: italic;
}

#desc_f .link_plus {
  margin-left: .5rem;
}

#desc_f .link_plus a {
  color: #6e6e6e;
}

#desc_f .link_plus a:focus,
#desc_f .link_plus a:hover {
  color: #3d3d3d;
}

.cd-main {
  z-index: 21;
  /*overflow-y: auto;*/
  backface-visibility: hidden;
  transition: transform .7s;
  transition-timing-function: cubic-bezier(.91,.01,.6,.99);
}

.cd-nav {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  transition: visibility 0s .7s;
}

#kpop_contain {
  margin-bottom: 2rem;
}

#kpop_contain img.fullimg {
  max-height: 80vh;
}

#kpop_contain img.fullimg {
  max-height: calc(100vh - 25vh);
}

.navigation-is-open .cd-main {
  transform: translateX(100%);
}

.navigation-is-open .titre,
.navigation-is-open h1 {
  margin-bottom: 2rem;
}

.cd-nav .cd-navigation-wrapper {
  text-align: left;
  height: 100%;
  overflow-y: auto;
  padding: 4rem 5% 4rem 5%;
  box-sizing: border-box;
  backface-visibility: hidden;
  transform: translateX(-50%);
  transition: transform .7s;
  transition-timing-function: cubic-bezier(.86,.01,.77,.78);
}

.cd-nav-trigger {
  display: inline-block;
  margin-bottom: 2rem;
  cursor: pointer;
  z-index: 50;
  height: 5.4rem;
  width: 5.4rem;
  background-color: #0082b8;
  border-radius: 50%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  transition: transform .5s,background-color .2s ease-in;
}

.cd-nav-trigger:focus,
.cd-nav-trigger:hover {
  background-color: #6e6e6e
}

.cd-nav-trigger .cd-nav-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%);
  width: 2.2rem;
  height: .2rem;
  background-color: #fff;
}

.cd-nav-trigger .cd-nav-icon::before,
.cd-nav-trigger .cd-nav-icon:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: inherit;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform .5s,width .5s,top .3s;
  width: 50%;
}

.cd-nav-trigger .cd-nav-icon::before {
  transform-origin: right top;
  transform: rotate(45deg);
}

.cd-nav-trigger .cd-nav-icon::after {
  transform-origin: right bottom;
  transform: rotate(-45deg);
}

.cd-nav-trigger svg {
  position: absolute;
  top: 0;
  left: 0;
}

.navigation-is-open .cd-nav {
  visibility: visible;
  transition: visibility 0s 0s;
}

.navigation-is-open .cd-nav .cd-navigation-wrapper {
  transform: translateX(0);
  transition: transform .5s;
  transition-timing-function: cubic-bezier(.82,.01,.77,.78);
}

.navigation-is-open .cd-nav-trigger {
  transform: rotate(180deg);
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after,
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  width: 50%;
  transition: transform .5s,width .5s;
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  transform: rotate(45deg);
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after {
  transform: rotate(-45deg);
}

.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::after,
.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::before {
  top: 0;
}