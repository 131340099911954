#contenu{
  margin-bottom: 2rem;
}

.sep {
  margin: 1.5rem 0;
  height: .1rem;
  background-color: #d4d8da
}

.sep-vertical{
  display: inline-block;
  height: 1.6rem;
  width: .1rem;
  margin: 0 .8rem -.2rem;
  background-color: $color1;
}

.sep-horizontal{
  display: block;
  height: .1rem;
  width: 100%;
  background-color: $color8;
}

.sep-horizontal-short{
  display: block;
  height: .1rem;
  width: 50%;
  min-width: 30rem;
  background-color: $color8;
  margin: 3rem auto;
}

.sep-horizontal-mobile{
  display: none;
}

@media (max-width: $breakpoint-sm) {
  .sep-horizontal-mobile{
    display: block;
    width: 25rem;
    height: .1rem;
    background-color: $color8;
    margin: 1.5rem auto 0;
  }
}