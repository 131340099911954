#chemin {
  position: relative;
  display: flex;
  align-items: center;
  background: #f4f4f4;
  overflow: hidden;
  min-height: 4rem;
  margin-bottom: 2rem;
  display: none;
  a{
    color: $color2;
    margin-left: 1rem;
    margin-right: 1rem;
    &:hover{
      color: $color3;
    }
  }
}