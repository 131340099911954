.log_error {
  text-align: left;
  color: #d8000c;
  font-weight: 700;
  li{
    line-height: 2rem;
    margin: .5rem 0;
  }
  .picto {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    margin-right: .5rem;
    text-align: center;
    border: .1rem solid #d8000c;
    border-radius: 50%;
  }
}

.error_border {
  border: .2rem solid #d8000c!important;
}

.error {
  display: inline-block;
  color: #fff;
  background-color: #a72430;
  margin: 1rem 0;
  padding: .8rem;
}

.success {
  display: inline-block;
  color: #000;
  background-color: #00b229;
  margin: 1rem 0;
  padding: .8rem;
}

/* form/default.tpl */
.form_control,
.form_control *{
  box-sizing: border-box;
}

.form_control label {
  display: inline-block;
  margin-bottom: .5rem;
  cursor: pointer;
  width: 8rem;
}

.form_control input[type=email],
.form_control input[type=password],
.form_control input[type=text],
.form_control select,
.form_control textarea {
  padding: .5rem 1rem;
  border: .1rem solid rgba(0,0,0,.2);
  outline: 0;
  margin: 0 auto 1rem auto;
  border-radius: .2rem;
  line-height: 2.2rem;
}

.form_control input[type=email]:focus,
.form_control input[type=password]:focus,
.form_control input[type=text]:focus,
.form_control select:focus,
.form_control textarea:focus {
  border-color: #0082b8;
}

.form_control textarea {
  width: 100%;
}

/* Focus effect sur input */
.custom-form{
  input[type="email"],
  input[type="text"],
  input[type="password"],
  textarea,
  select{
    height: 5.5rem;
    width: 100%;
    /*padding-top: 1.5rem;*/
    transition: all 0s;
    &:disabled{
      border-color: #eee;
    }
  }
  select{
    color: #777;
  }

  .input-effect{
    position: relative;
    margin-bottom: 2rem;
    text-align: left;

  }
  .focus-effect{
    padding: .7rem 1.5rem;
    border: .1rem solid #ccc;
    position: relative;
    background: transparent;
    ~ label{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 5.5rem;
      width: 100%;
      color: #888;
      background-color: #fff;
      margin-bottom: 0;
      padding-left: 1.2rem;
      padding-top: 1.6rem;
      transition: all .2s linear;
      z-index: -1;
      letter-spacing: .5px;
    }
  }
  .focus-effect:focus ~ label,
  .has-content.focus-effect ~ label{
    padding-top: .2rem;
    font-size: 1.1rem;
    color: #888;
    padding-top: 0;
    transition: all .2s linear;
  }
}
/* /Focus effect */

.valid-form{
  input[type="email"],
  input[type="text"],
  input[type="password"],
  textarea,
  select{
    display: block;
    border: .1rem solid #dee2e6;
    padding-left: 1.2rem;

    &:focus{
      border-color: #a4afba;
      outline: none;
      box-shadow: transparent;
    }
    &.valid{
      box-shadow:  0 0 1.5px 1px green;
    }
    &.invalid{
      box-shadow:  0 0 1.5px 1px #781629;
    }
  }

  input[type="checkbox"] + label{
    margin-left: .8rem;
    font-weight: normal;
    cursor: pointer;
    margin-bottom: 0;
  }

  .icon-password{
    position: absolute;
    top: .9rem;
    right: .8rem;
    font-size: 3rem;
    cursor: pointer;
  }

  textarea{
    width: 100%;
    resize: none;
    height: 15rem;
  }

  em.valid,
  em.invalid{
    display: inline-block;
    margin-top: .4rem;
    padding-left: 1.2rem;
    font-size: 1.2rem;
    font-style: italic;
    line-height: 1.1;
  }

  em.invalid {
    color: #781629;
  }

  em.invalid + em.valid{
    display: none;
  }
}



.fond_form {
  position: relative;
  z-index: 1;
}



/* radio */
input[type="radio"] {
  &.item-radio {
    display: none;

    + label {
      position: relative;
      padding-left: 4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      cursor: pointer;

      &::before,
      &::after {
        display: block;
        position: absolute;
        box-sizing: border-box;
        content: '';
        border-radius: 50%;
      }

      &::before {
        bottom: 0;
        left: 0;
        border: 1px solid #ccc;
        background-color: #eee;
        width: 3rem;
        height: 3rem;
      }

      &::after {
        bottom: 5px;
        left: 5px;
        width: calc(3rem - 10px);
        height: calc(3rem - 10px);
      }
    }

    &:checked + label::after {
      background-color: #ea5b0c;
    }
  }
}



/* Toast */
.alert-container {
  margin: 2rem auto;
  .alert {
    &.alert-danger {
      color: #c00;
    }
    &.alert-success {
      color: #00b229;
    }

  }
}