/*** Style generique ***/
body,
button,
fieldset,
form,
html,
textarea {
  font-family: 'Futura Std', sans-serif;
}

body,
html {
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-size: 1.8rem;
}

body,
body.mce-content-body {
  color: $color6;
  background-color: $color1;
}

.scroller {
  /*overflow-x: hidden;*/
}

.aheight {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle
}
.mini,
.fontsize80 {
  font-size: 80%
}

.fontsize85 {
  font-size: 85%
}

.fontsize90 {
  font-size: 90%
}

.fontsize110 {
  font-size: 110%
}

.big,
.fontsize120 {
  font-size: 120%
}
.fontsize140 {
  font-size: 140%
}
.fontsize160 {
  font-size: 160%
}

.lowercase {
  text-transform: lowercase
}

.uppercase {
  text-transform: uppercase
}
.nocase {
  text-transform: none;
}

figure {
  margin: auto;
}

.hidden,
.mobile,
figcaption {
  display: none;
}

.desktop {
  display: block;
}

:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
::placeholder {
  color: #91a1a1;
  font-style: italic;
  text-transform: none;
  font-weight: 700;
  font-size: 1.3rem;
}

img {
  max-width: 100%;
  height: auto;
}

.cms img {
  height: auto;
}

.icon,
.text {
  display: inline-block;
  vertical-align: middle;
}


// Liens
a {
  transition: all .3s ease-in;
}

.cms a:not(.button),
.mce-content-body a:not(.button),
a {
  color: $color6;
  cursor: pointer;
}

.cms a:not(.button):focus,
.cms a:not(.button):hover,
.mce-content-body a:not(.button):focus,
.mce-content-body a:not(.button):hover,
a:focus,
a:hover {
  color: $color2;
  text-decoration: none;
}

.link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// Listes
#builder .texte_content ol,
#builder .texte_content ul,
.cms ol,
.cms ul,
.faq_answer ol,
.faq_answer ul,
.mce-content-body ol,
.mce-content-body ul {
  line-height: 2rem;
  list-style-type: inherit;
  padding-left: 0;
}

#builder .texte_content ol li,
#builder .texte_content ul li,
.cms ol li,
.cms ul li,
.faq_answer ol li,
.faq_answer ul li,
.mce-content-body ol li,
.mce-content-body ul li {
  list-style-position: inside;
  margin: .6rem auto .6rem 2rem;

  &::marker {
    color: $color3;
  }
}

#builder .texte_content ol,
.cms ol,
.faq_answer ol,
.mce-content-body ol {
  list-style-type: decimal;
}

.liste li {
  list-style-type: square;
  list-style-position: inside;
  margin-left: 1rem;
  padding: .2rem 0;
}

// Tables
.table {
  display: table;
  border: .1rem solid #e3e3e3;
  border-collapse: collapse;
  font-size: 1.1rem;
  background-color: #fff;
  color: #3d3d3d;
}

.table-container {
  width: 100%;
  overflow-y: auto;
}

.table td,
.table th {
  border: .1rem solid #e3e3e3;
  padding: .5rem 1rem;
}

.table th {
  padding: 1rem;
}

.td {
  display: table-cell;
}

.cms{
  .video_container{
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }
  .box_shadow {
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, 0.5);
  }
}

// Accordeon
.entete_accordeon,
.ui-accordion .entete_accordeon {
  background: #209cbf none repeat scroll 0 0;
  border: .1rem solid #209cbf;
  color: #fff;
  font-size: 1.9rem;
  padding: .3rem 1rem;
  border-radius: 0;
}

.mce-content-body .stitre.entete_accordeon:after,
.mce-content-body h2.entete_accordeon:after,
.mce-content-body h2:after {
  border-bottom: none;
  margin: auto;
  width: auto;
  display: inline-block;
}

// Video
#form-builder .liste-content .video_content,
#grid_builder .content.type-video,
#video_home .video_c {
  width: 100%;
  padding-top: 56%;
  height: 0;
  position: relative;
}

#grid_builder .content.type-video .abs_ratio,
#video_home .absolute_c {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#form-builder .liste-content .video_content iframe,
#grid_builder .content.type-video iframe,
#video_home .absolute_c iframe {
  max-width: 100%;
  min-width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*** /Style generique ***/

#grid_builder_content .row {
  margin: 0 auto 2rem auto;
  padding: 2rem;
  overflow: hidden;
  background-color: #fff;
}

#body_cms .scroller > .content {
  margin-top: 5rem;
}

#body_cms #img_accueil {
  margin-top: -5rem;
}

#body_cms #bloc {
  padding: 2rem 2rem 0;
}

#body_cms .content .container {
  margin: 0 auto;
  width: 114rem;
  overflow: hidden;
  background-color: #fff;
  min-height: 35rem;
}

#body_cms a.bx-next,
#body_cms a.bx-prev {
  color: #fff;
}

/* Correction page Merci */
html body#cms.page_cms.cms_code_merci div.container.principal{
  min-height: 700px;
  padding-top: 3%;
  h1{
    justify-content: center;
  }
  p{
    text-align: center;
    margin: 2% 0% 5% 0%;
  }
}
