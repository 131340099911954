#map {
  .leaflet-popup,
  .leaflet-popup .leaflet-popup-content-wrapper,
  .leaflet-popup .leaflet-popup-content{
    width: 20rem!important;
    height: 12rem;
  }

  .leaflet-popup-content{
    padding: 1rem;
  }
}