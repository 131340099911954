//@font-face {
//  font-family: 'Poppins';
//  src: local('Poppins Light'), local('Poppins-Light'),
//  url('../fonts/subset-Poppins-Light.woff2') format('woff2'),
//  url('../fonts/subset-Poppins-Light.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Futura Std';
  src: local('Futura Std Book'), local('FuturaStd-Book'),
  url('../fonts/subset-FuturaStd-Book.woff2') format('woff2'),
  url('../fonts/subset-FuturaStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: local('Futura Std Medium'), local('FuturaStd-Medium'),
  url('../fonts/subset-FuturaStd-Medium.woff2') format('woff2'),
  url('../fonts/subset-FuturaStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: local('Futura Std Bold'), local('FuturaStd-Bold'),
  url('../fonts/subset-FuturaStd-Bold.woff2') format('woff2'),
  url('../fonts/subset-FuturaStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bauhaus Std';
  src: local('Bauhaus Std Bold'), local('BauhausStd-Bold'),
  url('../fonts/subset-BauhausStd-Bold.woff2') format('woff2'),
  url('../fonts/subset-BauhausStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



/* desactive pour le backo */
html body:not([class*="breakpoint"]) {

  .futura {
    font-family: 'Futura Std', sans-serif;
  }
  .bauhaus {
    font-family: 'Bauhaus Std', fantasy, sans-serif;
  }

}