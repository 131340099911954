/* /!\ Completer les couleurs dans "Visualiser" et "Variables" */
/* Couleur pour visualiser rapidement */

$color1:  #ffffff;
$color2:  #581d53;
$color3:  #ea5b0c;
$color4:  #009fe3;
$color5:  #3aaa35;
$color6:  #1d1d1b;
$color7:  #f2f2f1;
$color8:  #d9d9d9;
$color9:  #E6E5E4;
$color10: #000000;
$color11: #f168e6;
$color12: #EDEDED;
$color13: #781629;


/* Variables couleurs */
$colors: (
        "1":  #ffffff,
        "2":  #581d53,
        "3":  #ea5b0c,
        "4":  #009fe3,
        "5":  #3aaa35,
        "6":  #1d1d1b,
        "7":  #f2f2f1,
        "8":  #d9d9d9,
        "9":  #E6E5E4,
        "10": #000000,
        "11": #f168e6,
        "12": #EDEDED,
        "13": #781629,
);

/* desactive pour le backo */
body:not([class*="breakpoint"]) {

  /* Genere automatiquement les styles generiques du projet en fonction des couleurs */
  @each $number-color, $color in $colors {

    /* color */
    .color#{$number-color},
    .color#{$number-color} a { color: $color; }

    /* background-color */
    .bg#{$number-color} { background-color: $color; }

    /* border color */
    .border#{$number-color} { border: 1px solid $color; }

    /* separator color */
    .sep#{$number-color} { margin: 1.5rem 0; height: .1rem; background-color: $color; }

    /* button generique */
    /* Si blanc */
    @if ($color == #ffffff or $color == #f2f2f1 or $color == #E6E5E4) {
      .button.button#{$number-color} {
        background-color: $color; color: #333; border: 1px solid $color;

        &:hover, &:focus {
          background-color: #333; color: $color; border: 1px solid $color;
        }
      }
    }
    @else {
      .button.button#{$number-color} {
        background-color: $color; color: #fff; border: 1px solid $color;

        &:hover, &:focus {
          background-color: #fff; color: $color; border: 1px solid $color;
        }
      }
    }

  }

}


