/* menu catalogue */
.menu_catalogue {
  padding: 0;
  margin-left: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  list-style-position: inside;
  list-style-type: none;
  > li {
    > a {
      color: $color6;
      text-transform: uppercase;
      font-size: 3.2rem;
      font-weight: 500;
      @media (max-width: $breakpoint-lg) {
        font-size: 2.2rem;
      }
      &:hover,
      &:focus {
        opacity: 0.8;
      }
      .icon {
        display: none;
      }
      &.current {
        .icon {
          display: inline-block;
        }
      }
    }
    &.couleur_1 > a {
      color: $color4;
    }
    &.couleur_3 > a {
      color: $color5;
    }
    /*&:nth-child(1) > a {
      color: $color4;
    }
    &:nth-child(2) > a {
      color: $color5;
    }*/
  }
  .smenu_catalogue {
    padding: 0;
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    list-style-position: inside;
    list-style-type: none;

    > li {
      > a {
        color: $color6;
        font-size: 2rem;
        @media (max-width: $breakpoint-lg) {
          font-size: 1.4rem;
        }
        &:hover,
        &:focus {
          opacity: 0.8;
        }

      }
    }
  }
}

.m-sticky {
  position: sticky;
  left: 0;
  top: 10rem;
}
.card-container {
  .card {
    font-size: 2.4rem;
    a {
      color: $color6;
      figure {
        overflow: hidden;
        img {
          transition: all .2s ease-in;
        }
      }
      &:hover,
      &:focus {
        opacity: 0.8;
        figure {
          img {
            transform: scale(1.1);
          }
        }
      }
      .card-body {
        .title {
          margin-top: 2rem;
        }
        .button {
          cursor: pointer;
        }
      }
    }
  }
}

.product_card_list {
  .card {
    font-size: 2rem;
    @media (max-width: $breakpoint-md) {
      font-size: 1.6rem;
    }
    .img-hover-zoom {
      color: $color6;
      figure {
        overflow: hidden;
        img {
          transition: all .2s ease-in;
        }
      }
      &:hover,
      &:focus {
        opacity: 0.8;
        figure {
          img {
            transform: scale(1.1);
          }
        }
      }

      button {
        position: absolute;
      &.slick-prev{
          left: -4rem;
        }
      &.slick-next{
          right: -4rem;
        }
      }

    }
    .card-body {
      .title {
        margin-top: 2rem;
        &.h2 {
          margin-top: 0;
        }

      }
      .button {
        cursor: pointer;
      }
    }
    .infos_compl .item {
      &.libelle_4 > div {
        background-color: $color3;
      }

      > div {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 12rem;
        margin-top: 3rem;
        padding: 0.3rem;
        background-color: $color4;
        color: $color1;
        line-height: 1.2;

        font-size: 1.8rem;
        font-weight: 500;

        > span,
        > p > span{
          display: block;
          font-size: 4.2rem;
          font-weight: bold;
        }
      }
    }

  }
}

.tpl_catalogue-light {
  .couleur_1 {
    color: $color4;
  }
  .couleur_3 {
    color: $color5;
  }

  .titre_famille {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: $breakpoint-md) {
      font-size: 2.5rem;
    }
    .txt {

    }
    .icon {
      color: $color3;
      margin: auto 2rem;
    }
  }

  .famille_box {
    display: none;
  }
}

.badges {
  padding: 0;
  padding-top: 2rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  list-style-position: inside;
  list-style-type: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  > li {
    width: 10rem;
    margin: 1rem;
  }

}
.badges_2 {
  justify-content: start;
  li {
    width: 10rem;
    &:last-child {
      width: 22rem;
    }
  }
}

.docs {
  padding: 0;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style-position: inside;
  list-style-type: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  > li {
    margin: 1rem;
    a {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1;
      .icon {
        font-size: 5.2rem;
        color: $color3;
        transition: background 0.3s ease-in-out;
      }
      &:hover,
      &:focus {
        color: $color2;
        .icon {
          color: $color2;
        }
      }

    }
  }

}