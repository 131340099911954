.mp-menu {
  position: absolute; /* we can't use fixed here :( */
  top: 0;
  left: 0;
  z-index: 1;
  width: 30rem;
  height: 100vh;
  background: #fff;
  transform: translateX(-100%);
  transition: transform 0.5s;
}

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 5rem);
  overflow: hidden;
  background: #F4F4F4;
  transform: translateX(-100%);
}

.mp-menu > .mp-level {
  top: 10.5rem;
}

/* Partie Logo */
#logo_mh {
  @include flex_center;
  text-align: center;
  height: 10.5rem;
  background-color: $color2;
  img{
    width: 9rem;
  }
}

.mp-level.mp-level-open,
.mp-menu > .mp-level.mp-level-open {
  height: calc(100% - 5rem);
}

.mp-level.mp-level-open {
  overflow: auto;
  .mp-level-overlay {
    overflow: hidden;
    height: 200vh;
    > ul > li:not(.m_current) {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.mp-level.mp-level-overlay {
  cursor: pointer;
}

.mp-level.mp-level-overlay.mp-level:before {
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 1;
}

.mp-pusher,
.mp-level {
  transition: transform 0.5s;
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  box-shadow: none;
  transform: translateX(0);
  transition: transform 0.5s;
}

/* cover */
.mp-cover .mp-level.mp-level-open {
  transform: translateX(0);
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  transform: translateX(-100%);
}

/* titles */

.mp-menu .title {
  margin: 0 2rem;
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0 0 .1rem rgba(0, 0, 0, 0.1);
  font-size: 1.6rem;
  min-height: 4rem;
  line-height: 4rem;
  padding: 0.7rem 0;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  text-align: center;
}

#mp-menu .mp-level-2 .c_container span.text {
  vertical-align: middle;
  font-size: 2.5rem;
}

.mp-menu .title .picto + .text {
  margin-left: 0;
}

.mp-menu .mp-level .mp-level .mp-level .title {
  color: #ffffff;
  a{
    vertical-align: middle;
    line-height: 1.8rem;
    display: inline-block;
    padding-bottom: .3rem;
  }
}

.mp-menu .title a:hover, .mp-menu ul li .title a:hover {
  background: transparent;
  color: #56c1ff;
}

.mp-menu .title .picto,
.mp-menu .title a .picto {
  font-size: 4rem;
  line-height: 4rem;
  overflow: visible;
  width: auto;
  margin: 0 .5rem;
}

.mp-menu .title .picto + .centered-height {
  width: calc(100% - 6rem);
}

.mp-menu.mp-overlap .title:before {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: .8rem;
  font-size: 75%;
  line-height: 1.8;
  opacity: 0;
  transition: opacity 0.3s, transform 0.1s 0.3s;
  transform: translateX(-100%);
}

.mp-overlap .mp-level.mp-level-overlay > .title:before {
  opacity: 1;
  transition: transform 0.3s, opacity 0.3s;
  transform: translateX(0);
}

/* lists */

.mp-menu ul {
  list-style: none;
  text-align: left;
  overflow: hidden;
}

.mp-menu > .mp-level > ul {
  margin: 0;
  padding: 0;
  overflow: auto;
  width: 30rem;
  height: 100%;
  position: relative;
}

.mp-menu ul li:before {
  position: absolute;
  left: 1rem;
  z-index: -1;
  color: rgba(0, 0, 0, 0.8);
  line-height: 3rem;
}

/* lists links */

.mp-menu ul li > a {
  display: block;
  padding: 0.7em 1em 0.7em 1em;
  outline: none;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  .icon {
    font-size: 1.2rem;
  }
}

.mp-level a {
  color: rgba(0, 0, 0, 0.7);
}

#mp-menu .mp-level ul {
  list-style: none;
}

#mp-menu .mp-level-2 a {
  text-transform: none;
  line-height: 2rem;
  vertical-align: middle;
}

#mp-menu .mp-level-2 .c_container a,
#mp-menu .mp-level-2 .c_container span {
  color: #000;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
}

#mp-menu .mp-level-2 .c_container span {
  color: rgba(0, 0, 0, 0.7);
  transition: all .3s;
  &:hover{
    color: #bcc6cf;
  }
}

#mp-menu .mp-level-2 a.mp-back {
  text-transform: uppercase;
  padding-left: 1rem;
  &:hover{
    color: $color2;
    text-decoration: none;
    padding-left: 2rem;
  }
}

#mp-menu .mp-level-2 ul {
  padding: 0;
  margin: 0;
  li{
    padding: 0 .5rem;
  }
}

.mp-level a{
  padding-left: 0;
  vertical-align: middle;
  transition: border-color 0.2s ease-in, padding 0.2s ease-in;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.mp-level > ul > li > a{
  transition: all .3s;
  &:hover,
  &:focus{
    color: $color2;
    text-decoration: none;
    padding-left: 2em;
  }
}

.mp-level .espace_mobile {
  background-color: transparent;
  border: none;
  line-height: 2.2rem;
  padding: 0.7em 1em 0.7em 1em;
  outline: none;
  cursor: pointer;
  color: #fff;
  .picto {
    margin: 0 1rem 0 0;
    font-size: 2.2rem;
    width: 2.4rem;
    overflow: visible;
  }
}

.mp-level .espace_mobile:hover .text,
.mp-level .espace_mobile:hover .picto {
  color: #000;
}


.mp-level > ul > li > a {
  box-shadow: inset 0 -.1rem rgba(0, 0, 0, 0.2);
}

.mp-menu.out ul li a:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -.1rem rgba(0, 0, 0, 0);
}

#mp-menu .mp-level .picto_menu {
  position: static;
  margin: 0 0 0 .6rem;
  vertical-align: middle;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.7);
  transition: border-color 0.2s ease-in, margin 0.3s ease-in;
}

#mp-menu .mp-level a:hover .picto_menu {
  border-color: transparent transparent transparent #ffffff;
}

/* back buttons */

.mp-back {
  background: rgba(0, 0, 0, 0.1);
  outline: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-weight: 700;
  display: block;
  font-size: 1.2em;
  padding: 1em 1em 1em 2.4em;
  position: relative;
  box-shadow: inset 0 .1rem rgba(0, 0, 0, 0.1);
  transition: color 0.2s, background 0.3s, padding 0.2s;
}

.mp-back:hover {
  padding: 1em 1em 1em 2.7em;
}

.mp-menu.out .mp-level.mp-level-overlay > .mp-back {
  background: transparent;
  box-shadow: none;
  color: transparent;
}

#mp-menu .mp-back::before, #mp-menu .mp-level-2 .mp-back::before {
  content: "\EA02";
  font-family: 'kfont'!important;
  vertical-align: middle;
  margin-right: 1rem;
}

#mp-menu .mp-back .picto_menu {
  border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
}

#mp-menu .mp-level .mp-back:hover .picto_menu {
  border-color: transparent #ffffff transparent transparent;
}

.mp-level li {
  margin-left: 0;
}

.m_event:after {
  content: "\EA03";
  font-family: 'kfont'!important;
  margin-right: 4rem;
  vertical-align: middle;
  float: right;
}

/* menu en plus */
.mp-menu {
  .mm-plus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    a {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: $color2;
      color: $color1;
      .icon {
        font-size: 3rem;
      }
      &:hover,
      &:focus {
        color: $color11;
      }
    }
  }
}

/* fancy mobile rules */
.fancybox-inner, #fancybox-content > div {
  overflow: hidden !important;
}

#fancybox-content > div img {
  width: 100%;
}

/* ***************** */
.mp-pusher {
  transition: transform 0.5s;
}

.fancy_visible .scroller, .menu_visible .scroller {
  overflow: hidden;
  height: 100%;
}

.menu_visible html {
  overflow: hidden;
}

.mp-fancy {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(-34rem);
  width: 34rem;
  z-index: 1;
}

.scroller:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: '';
  display: block;
  z-index: 9999;
}

.scroller.mp-pushed {
  cursor: pointer;
}

.scroller.mp-pushed:before {
  left: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background: rgba(0, 0, 0, 0.5);
  transition: background 0.5s;
}

.mp-pusher.fancy_visible {
  transform: translateX(34rem);
}

.mp-pusher.menu_visible {
  transform: translateX(30rem);
}

.mp-pusher::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: '';
  opacity: 0;
}

.mp-pusher.fancy_visible {
  transform: translateX(34rem);
}

#mp-fancy > .header {
  border-bottom: .5rem solid #5cb39b;
  position: relative;
}

/* Launcher du menu */
#menu_launcher {
  position: relative;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
}

#header.sticky #menu_launcher {
  top: .4rem;
}

/* hamburger */

.picto-menu {
  position: absolute;
  top: 1.6rem;
  left: .6rem;
  width: 2.8rem;
  height: .3rem;
  background: $color1;
  display: inline-block;
  transition: all 0.3s ease-in-out 0s;
}

.picto-menu:before, .picto-menu:after {
  background: $color1;
  content: "";
  display: inline-block;
  height: .3rem;
  left: 0;
  position: absolute;
  transform-origin: center center 0;
  transition: all 0.3s ease-in-out 0s;
  width: 2.8rem;
}

.picto-menu:before {
  top: .8rem;
}

.picto-menu:after {
  top: -.8rem;
}

.menuhidden .picto-menu:before {
  height: .2rem;
  transform: rotate(45deg);
}

.menuhidden .picto-menu:after {
  height: .2rem;
  transform: rotate(-45deg);
}

.menuhidden .picto-menu:before, .menuhidden .picto-menu:after {
  top: 0;
  transform-origin: 50% 50% 0;
}

.menuhidden .picto-menu {
  background: transparent none repeat scroll 0 0;
}

@media (min-width: 991px) {
  #menu_launcher {
    display: none;
  }
}

@media (max-width: 767px) {
  .mp-level.mp-level-open.mp-level-overlay {
    overflow: hidden;
    height: 200vh;
  }
}